import { type ReactNode, useCallback, useEffect, useRef, useState } from "react";

import { Slide } from "react-awesome-reveal";
import { type AvaFeedback } from "@doitintl/cmp-models";
import { Stack } from "@mui/material";

import { type Message, useTrpcContext } from "../Common";
import { currentPresetPromptsContext } from "../Common/PresetPromptsContext";
import { useAva } from "../useAva";
import { useHistoryToShow, useMessagesInProgress } from "./hooks";
import { MessageAvaComponent, MessageUserComponent } from "./Message";
import { MessengerFooter } from "./MessengerFooter";

type ConversationData = {
  history: Message[];
  conversationId: string;
};

type Props = {
  children?: ReactNode;
  widgetCards?: Record<string, ReactNode>;
  onConversationsStarted?: (conversationId: string) => void;
  conversationData?: ConversationData;
  isDarkMode: boolean;
  getAvatar?: () => string | null;
  clientName?: "console" | "help-center";
  onAvaReportResponse?: (report: string, mapKey: string) => void;
  contactSalesButton?: ReactNode;
  progressComponent: ReactNode;
  handleReportCreate?: (widgetKey: string) => Promise<void>;
};

export const Messenger = ({
  conversationData,
  children,
  widgetCards,
  onConversationsStarted,
  isDarkMode,
  getAvatar,
  clientName,
  onAvaReportResponse,
  contactSalesButton,
  progressComponent,
  handleReportCreate,
}: Props) => {
  const [processingQuestion, setProcessingQuestion] = useState(false);
  const [generationStopped, setGenerationStopped] = useState(false);

  const handleFinishGeneration = useCallback(() => {
    setProcessingQuestion(false);
    setGenerationStopped(false);
  }, []);
  const getAbortStatus = useCallback(() => generationStopped, [generationStopped]);

  const { answer, answerExtras, askQuestion, onStopGeneration, overrideLongerWait } = useAva(
    handleFinishGeneration,
    clientName,
    getAbortStatus
  );

  const { fetchFeedback } = useTrpcContext();

  const [currentConversationId, setCurrentConversationId] = useState<string | undefined>(
    conversationData?.conversationId
  );

  const [error, setError] = useState<string | undefined>();
  const [question, setQuestion] = useState("");
  const [keepScrollingToBottom, setKeepScrollingToBottom] = useState(true);
  const [newQuestionAsked, setNewQuestionAsked] = useState(false);
  const stackRef = useRef<HTMLDivElement>(null);
  const { messageUserInProgress, messageAvaInProgress } = useMessagesInProgress(
    generationStopped,
    question,
    answer,
    answerExtras
  );

  const historyToShow = useHistoryToShow(
    question,
    processingQuestion,
    conversationData?.history,
    answerExtras?.answerId
  );
  const handleScroll = useCallback(() => {
    if (stackRef.current && processingQuestion) {
      const { scrollHeight, scrollTop, clientHeight } = stackRef.current;
      setKeepScrollingToBottom(scrollHeight - scrollTop <= clientHeight + 2);
    }
  }, [stackRef, processingQuestion]);

  const scrollToBottom = useCallback(() => {
    if (!stackRef.current) {
      return;
    }
    if (keepScrollingToBottom) {
      stackRef.current.scrollTop = stackRef.current.scrollHeight;
    }
  }, [stackRef, keepScrollingToBottom]);

  const feedbackClicked = useCallback(
    async (answerRef: string, feedback: AvaFeedback) => {
      if (!currentConversationId) {
        return;
      }

      await fetchFeedback(currentConversationId, answerRef, feedback);

      if (!stackRef.current) {
        return;
      }
      const { scrollHeight, scrollTop, clientHeight } = stackRef.current;
      const itsInTheLastMessage = scrollHeight - scrollTop <= clientHeight + 150;
      if (itsInTheLastMessage) {
        stackRef.current.scrollTop = stackRef.current.scrollHeight;
      }
    },
    [currentConversationId, fetchFeedback]
  );

  const handleStopGeneration = useCallback(() => {
    setGenerationStopped(true);
    setProcessingQuestion(false);
    if (conversationData?.conversationId && answerExtras?.answerId) {
      onStopGeneration(conversationData?.conversationId, answerExtras?.answerId, answer);
    }
  }, [conversationData?.conversationId, answerExtras?.answerId, answer]);

  useEffect(() => {
    scrollToBottom();
  }, [answer, answerExtras, scrollToBottom, conversationData?.history]);

  const handleAskQuestion = async (question: string) => {
    setKeepScrollingToBottom(true);
    setNewQuestionAsked(true);
    setQuestion(question);
    setProcessingQuestion(true);
    setError(undefined);
    setGenerationStopped(false);

    try {
      await askQuestion({
        conversationId: currentConversationId,
        question,
        conversationCallback: (conversationId) => {
          setCurrentConversationId(conversationId);
          onConversationsStarted?.(conversationId);
        },
      });
    } catch (e: any) {
      setError(e.message);
    }
  };

  const handleRetryClicked = async () => {
    if (question.length > 0) {
      await handleAskQuestion(question);
    }
  };

  return (
    <Stack
      sx={{
        height: "100%",

        backgroundColor:
          !currentConversationId && !processingQuestion && !isDarkMode ? "#FAFAFA" : "general.backgroundDark",

        justifyContent: "flex-end",
      }}
    >
      {(currentConversationId || processingQuestion) && (
        <Stack
          onScroll={handleScroll}
          ref={stackRef}
          spacing={2}
          sx={{ overflow: "hidden auto", scrollbarWidth: "none", px: 0, py: 2 }}
        >
          {historyToShow.map((message) => {
            if (message.role === "user") {
              return (
                <MessageUserComponent
                  key={message.createdAt.getTime()}
                  getAvatar={getAvatar}
                  message={message}
                  disableAnimation={true}
                />
              );
            }
            if (message.role === "ava") {
              return (
                <MessageAvaComponent
                  onReportGenerated={onAvaReportResponse}
                  widgetCards={widgetCards}
                  key={message.createdAt.getTime()}
                  message={message}
                  disableAnimation={true}
                  onFeedbackClicked={feedbackClicked}
                  isDarkMode={isDarkMode}
                  overrideLongerWait={overrideLongerWait}
                  contactSalesButton={contactSalesButton}
                  handleReportCreate={handleReportCreate}
                  handleAskQuestion={handleAskQuestion}
                />
              );
            }
          })}

          {newQuestionAsked && (
            <Slide direction="up" duration={300} triggerOnce>
              <MessageUserComponent message={messageUserInProgress} disableAnimation={false} getAvatar={getAvatar} />
              <MessageAvaComponent
                onReportGenerated={onAvaReportResponse}
                widgetCards={widgetCards}
                message={messageAvaInProgress}
                onFeedbackClicked={feedbackClicked}
                showError={error}
                disableAnimation={false}
                onRetryClicked={handleRetryClicked}
                isDarkMode={isDarkMode}
                overrideLongerWait={overrideLongerWait}
                contactSalesButton={contactSalesButton}
                handleReportCreate={handleReportCreate}
                handleAskQuestion={handleAskQuestion}
              />
            </Slide>
          )}
        </Stack>
      )}
      <currentPresetPromptsContext.Provider
        value={{
          setSelectedPrompt: (prompt) => handleAskQuestion(prompt),
        }}
      >
        {!currentConversationId && !processingQuestion && <>{children}</>}
      </currentPresetPromptsContext.Provider>
      {progressComponent}
      <MessengerFooter
        onAskQuestionClicked={handleAskQuestion}
        disableInput={processingQuestion}
        isHelpCenter={clientName === "help-center"}
        showStopButton={processingQuestion && !generationStopped && !!answerExtras?.answerId}
        handleStopGeneration={handleStopGeneration}
      />
    </Stack>
  );
};
