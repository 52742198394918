import { useCallback, useState } from "react";

import { useParams } from "react-router";
import { CloudFlowNodeType } from "@doitintl/cmp-models";
import { type Node, useOnSelectionChange, useReactFlow } from "@xyflow/react";

import NodeConfigurationPanel from "../CloudflowBuilder/ConfigurationPanel/NodeConfigurationPanel";
import { applyGraphLayout } from "../CloudflowBuilder/utils/layoutUtils";
import { updateSelectedNode } from "../CloudflowBuilder/utils/updateUtils";
import type { HandleUpdateNodeFn, RFNode } from "../types";

type Props = {
  setShowChangeTriggerDialog: (show: boolean) => void;
  setIsSearchModalVisible: (visible: boolean) => void;
};

const SidePanelContent = ({ setShowChangeTriggerDialog, setIsSearchModalVisible }: Props) => {
  const { flowId } = useParams<{ customerId: string; flowId: string }>();
  const { setNodes, getEdges } = useReactFlow();
  const [activeNode, setActiveNode] = useState<Node<RFNode> | undefined>();

  const handlePanelClose = useCallback(() => {
    setNodes((prevNodes) =>
      prevNodes.map((node) => {
        if (node.id !== activeNode?.id) return node;

        return { ...node, selected: false };
      })
    );
  }, [activeNode, setNodes]);

  const onChange = useCallback(({ nodes }: { nodes: Node[] }) => {
    if (nodes.length === 0) {
      setActiveNode(undefined);
      return;
    }

    const node = nodes[0];
    if (node.type === CloudFlowNodeType.GHOST || node.type === CloudFlowNodeType.START_STEP) {
      return;
    }
    setActiveNode(node as Node<RFNode>);
  }, []);

  useOnSelectionChange({
    onChange,
  });

  const handleUpdateNodeConfig: HandleUpdateNodeFn = useCallback(
    (id, updateNodeAction) => {
      setNodes((prevNodes) => {
        const updatedNodes = prevNodes.map((node) => {
          if (node.id !== id) return node;

          const updatedNode = updateSelectedNode(node as Node<RFNode>, updateNodeAction);
          setActiveNode(updatedNode);
          return updatedNode;
        });
        const { positionedNodes } = applyGraphLayout(updatedNodes as Node<RFNode>[], getEdges());
        return positionedNodes;
      });
    },
    [getEdges, setNodes]
  );

  if (!activeNode) {
    return null;
  }

  return (
    <NodeConfigurationPanel
      flowId={flowId}
      open={!!activeNode && activeNode.type !== CloudFlowNodeType.GHOST}
      onClose={handlePanelClose}
      activeNode={activeNode}
      onUpdateNode={handleUpdateNodeConfig}
      onChangeTriggerType={() => {
        setShowChangeTriggerDialog(true);
      }}
      onChangeAction={() => {
        setIsSearchModalVisible(true);
      }}
    />
  );
};

export default SidePanelContent;
